html,
body,
app-root {
  height: 100vh;
  display: block;
  min-height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 100);
}
body {
  overflow: hidden;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  transition: opacity 0.7s linear;
}
#block-ui-wrapper {
  z-index: 3000;
}
.app-gbd .heightWithoutHeader {
  height: calc( 100vh - 48px );
}
.app-gbd nav.b-navigation__in-page-nav {
  display: flex;
  width: 100% !important;
  max-width: 100% !important;
}
.app-gbd nav.b-navigation__in-page-nav .b-navigation__list {
  text-transform: uppercase;
}
.app-gbd .landing-inside,
.app-gbd w-sticky-bar {
  max-width: 100% !important;
}
.app-gbd .b-input__input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.app-gbd .b-input__label,
.app-gbd .b-input--has-content .b-input__input {
  padding-top: 3px;
  padding-bottom: 3px;
}
.app-gbd .b-input--has-content .b-input__label {
  padding-top: 3px;
  padding-bottom: 3px;
}
.app-gbd .b-input__checkbox-item--label-2 {
  flex-shrink: 0;
}
@media (max-width: 800px) {
  .app-gbd w-sticky-bar {
    padding-left: 15px !important;
  }
}
.app-gbd .b-edit-panel,
.app-gbd .b-edit-panel__section-group {
  margin-bottom: 0px;
}
.app-gbd .b-tablist__tab {
  cursor: auto;
}
.app-gbd .b-tablist__tab button[w-button][ng-reflect-is-active='true'] {
  pointer-events: none;
  cursor: help;
}
.app-gbd search-results-menu w-filter.buttons ul.b-tablist {
  margin: 0;
}
.app-gbd w-validation-panel[type=error] * {
  color: #ff8787;
}
.app-gbd w-input-date {
  max-height: 60px;
}
.app-gbd w-input-date input {
  max-height: 60px;
}
.app-gbd w-input-text input {
  max-height: 60px;
}
.app-gbd w-select-one select {
  margin-top: 10px;
  max-height: 50px;
}
.app-gbd .b-sidebar-host .b-sidebar .b-sidebar__content {
  padding: 0 0.1em;
}
.app-gbd .b-edit-panel__content {
  overflow: initial !important;
}
.app-gbd .b-edit-panel__section-group,
.app-gbd .b-edit-panel__section-auto-group {
  box-shadow: 0 0px 0px rgba(0,0,0,0.1);
}
.app-gbd .b-view-panel--is-ulf.b-view-panel .b-view-panel__content {
  background-color: #fff;
}
.app-gbd .b-view-panel__slot {
  background-color: var(--w-ulf-background-color);
}
.app-gbd .b-view-panel .b-view-panel--title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  line-height: 24px;
  padding-top: 10px;
}
.app-gbd .b-view-panel--is-ulf.b-view-panel .b-view-panel__content > div.b-view-panel__content-sections .b-view-panel__content-section-top > div:first-child {
  padding: 0 10px;
}
.app-gbd .b-view-panel--is-ulf.b-view-panel .b-view-panel__content > div.b-view-panel__content-sections .b-view-panel__content-section-top .b-view-panel__actions > .b-view-panel__action-secondary {
  position: relative;
}
.app-gbd .b-edit-panel__section,
.app-gbd .b-view-panel__section {
  min-height: 0px !important;
}
.app-gbd w-table-commands.navTools {
  padding: 0;
  margin: 0;
}
.app-gbd w-table-commands.navTools * {
  text-transform: uppercase;
}
.app-gbd w-table-commands.navTools .b-navigation__shortcut-item {
  padding: 0;
  font-weight: 400 !important;
}
.app-gbd w-sidebar-section {
  max-width: 300px;
}
.app-gbd .textBlock {
  padding: 1ch;
  background: #ededed;
  border: 1px solid #cbcbcb;
  color: #1a1a1a;
  margin-bottom: 3ch;
}
.app-gbd .textBlock.danger {
  background: #ff8686;
  color: #cb6c6c;
}
.app-gbd w-table-commands f-facet {
  position: relative;
}
.app-gbd w-table-commands f-facet li .tooltip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  display: none;
  background: #fff;
  box-shadow: 0px 4px 8px 4px rgba(0,0,0,0.18);
  padding: 1.5ch;
  z-index: 5;
}
.app-gbd w-table-commands f-facet li .tooltip.active {
  display: block;
}
.app-gbd w-table-commands f-facet li .tooltip:before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 12px 11px;
  border-color: transparent transparent #fff transparent;
}
.app-gbd w-table-commands f-facet li .tooltip ul li {
  width: 100%;
}
.app-gbd w-table-commands f-facet li .tooltip ul li label > input:first-child {
  margin-right: 0.5ch;
}
.app-gbd * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.app-gbd ul {
  list-style: none;
}
.app-gbd button {
  align-items: center;
  user-select: none;
}
.app-gbd button[disabled] {
  opacity: 0.5;
}
.app-gbd button i.fa,
.app-gbd button i.fas,
.app-gbd button svg {
  margin-right: 10px;
}
.app-gbd iframe {
  border: none;
}
.app-gbd small {
  display: block;
  font-size: small;
}
.app-gbd small * {
  font-size: small;
}
.app-gbd .big {
  display: block;
  font-size: small;
}
.app-gbd .big * {
  font-size: small;
}
.app-gbd [routerlink] {
  outline: none !important;
}
.app-gbd a,
.app-gbd a:hover {
  cursor: pointer;
  color: inherit;
}
.app-gbd .breakword {
  word-break: break-word;
}
.app-gbd input[type=checkbox] {
  cursor: pointer;
  transform: translateY(1px);
}
.app-gbd input[type=checkbox]:focus {
  box-shadow: none;
}
.app-gbd u,
.app-gbd .underline {
  text-decoration: underline;
}
.app-gbd pre {
  font-family: monospace;
  font-size: x-small;
  white-space: pre-wrap;
}
.app-gbd .addspaces > *:not(:last-child) {
  margin-right: 1ch;
}
.app-gbd .animated {
  transition: all 0.3s cubic-bezier(0.88, 0.07, 0.18, 0.97);
}
.app-gbd .animatedSlow {
  transition: all 0.7s cubic-bezier(0.88, 0.07, 0.18, 0.97);
}
.app-gbd .bgTransp {
  background: transparent !important;
}
.app-gbd .blurry {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.app-gbd .border {
  border: #00f dashed 1px;
}
.app-gbd .border.red {
  border: #f00 dashed 1px;
}
.app-gbd .border.green {
  border: #008000 dashed 1px;
}
.app-gbd .border.yellow {
  border: #ff0 dashed 1px;
}
.app-gbd .border.purple {
  border: #800080 dashed 1px;
}
.app-gbd button[disabled] {
  cursor: not-allowed !important;
}
.app-gbd .capitalized,
.app-gbd .capitalize {
  text-transform: capitalize;
}
.app-gbd .centered {
  text-align: center;
}
.app-gbd .cursorPointer,
.app-gbd .pointer {
  cursor: pointer;
}
.app-gbd .dev {
  background-color: #242424 !important;
  color: #fff !important;
  padding: 2px 5px !important;
  font-family: 'Roboto Mono', monospace !important;
  font-size: x-small !important;
  text-align: left !important;
  word-break: break-all;
  line-height: initial;
}
.app-gbd .dev * {
  color: #fff !important;
  font-size: inherit !important;
  font-family: 'Roboto Mono', monospace !important;
  word-break: break-all;
}
.app-gbd .displayBlock,
.app-gbd .dispBlock {
  display: block;
}
.app-gbd .displayNone,
.app-gbd .dispNone {
  display: none;
}
.app-gbd .flex {
  display: flex;
}
.app-gbd .center-h,
.app-gbd .center-all {
  justify-content: center;
}
.app-gbd .column {
  flex-direction: column;
}
.app-gbd .grow,
.app-gbd .flexgrow,
.app-gbd .flexGrow {
  flex-grow: 1;
}
.app-gbd .inline,
.app-gbd .center-v,
.app-gbd .center-all {
  align-items: center;
}
.app-gbd .inline:not(.column) > *,
.app-gbd .center-v:not(.column) > *,
.app-gbd .center-all:not(.column) > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.app-gbd .flex-end {
  justify-content: flex-end;
}
.app-gbd .row {
  flex-direction: row;
}
.app-gbd .space-around {
  justify-content: space-around;
}
.app-gbd .space-between {
  justify-content: space-between;
}
.app-gbd .stretch {
  align-items: stretch;
}
.app-gbd .wrap {
  flex-wrap: wrap;
}
.app-gbd .flexgrow,
.app-gbd .flex-grow {
  flex-grow: 1;
}
.app-gbd .fullscreen {
  width: 100vw;
  height: 100%;
  position: relative;
}
.app-gbd .fullwidth,
.app-gbd .fullWidth {
  width: 100%;
}
.app-gbd .grid {
  display: grid;
}
.app-gbd .hidden,
.app-gbd [hidden] {
  visibility: hidden !important;
}
.app-gbd .inverted {
  -webkit-filter: invert(100%);
}
.app-gbd label {
  cursor: pointer;
}
.app-gbd .noBorder,
.app-gbd .noborder {
  border: none !important;
}
.app-gbd .noBorder tr,
.app-gbd .noborder tr,
.app-gbd .noBorder td,
.app-gbd .noborder td {
  border: none !important;
}
.app-gbd .noevents,
.app-gbd .noEvents {
  pointer-events: none;
}
.app-gbd .nohighlight,
.app-gbd ui-switch {
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}
.app-gbd .noMargin,
.app-gbd .nomargin {
  margin: 0 !important;
}
.app-gbd .noPadding,
.app-gbd .nopadding {
  padding: 0 !important;
}
.app-gbd .nogrow,
.app-gbd .noGrow {
  flex-grow: 0;
}
.app-gbd .noshrink,
.app-gbd .noShrink {
  flex-shrink: 0;
}
.app-gbd .noselect,
.app-gbd .noSelect {
  user-select: none;
}
.app-gbd .nounderline,
.app-gbd .noUnderline {
  text-decoration: none !important;
}
.app-gbd .opaque {
  opacity: 1 !important;
}
.app-gbd .overlay {
  width: 200vw;
  height: 200vh;
  position: absolute;
  top: -100vh;
  left: -100vw;
  pointer-events: none;
}
.app-gbd .overlay.active {
  background-color: rgba(0,0,0,0.3);
  pointer-events: auto;
}
.app-gbd .positionInitial {
  position: initial;
}
.app-gbd .scroller {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 1;
  height: 100%;
  max-height: 100%;
}
.app-gbd .shadow {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.app-gbd .showOnHover {
  opacity: 0.2;
}
.app-gbd .showOnHover:hover {
  opacity: 1;
}
.app-gbd .showButtonsOnHover .buttons {
  opacity: 0.2;
}
.app-gbd .showButtonsOnHover:hover .buttons {
  opacity: 1;
}
.app-gbd .singleLine,
.app-gbd .nobreak,
.app-gbd .nowrap {
  white-space: nowrap;
}
.app-gbd .menu_tools {
  margin-top: 14px;
  font-size: 14px;
  color: #1a1a1a;
  white-space: nowrap;
}
.app-gbd .menu_tools span {
  padding-left: 5px;
}
@-webkit-keyframes spinning {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.app-gbd .spinning {
  -webkit-animation: spinning 2s linear infinite;
  -moz-animation: spinning 2s linear infinite;
  -ms-animation: spinning 2s linear infinite;
  -o-animation: spinning 2s linear infinite;
  animation: spinning 2s linear infinite;
}
.app-gbd .uppercase {
  text-transform: uppercase;
}
@-moz-keyframes spinning {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinning {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinning {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinning {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.app-gbd search-results-info {
  display: block;
}
.app-gbd label input[type=checkbox] {
  margin-right: 0.5ch;
}
.app-gbd .blueBarActions .addToReport h6 {
  font-size: initial;
}
.app-gbd .blueBarActions .addToReport ul li {
  margin: 1ch 0;
}
.app-gbd .blueBarActions .addToReport ul li.disabled label {
  pointer-events: none;
  opacity: 0.5;
}
.app-gbd .blueBarActions .addToReport ul li.disabled span.red {
  visibility: visible;
}
.app-gbd .blueBarActions .addToReport ul li span.red {
  color: #cb6c6c;
  visibility: hidden;
}
.app-gbd .viewed {
  background: #f0f0f0 !important;
}
.app-gbd .spacerBottom {
  height: 80px;
}
.app-gbd .scroller #humanAndSearch {
  grid-template-columns: 100fr 200px;
  background: #fff;
  padding: 20px;
}
.app-gbd .scroller #humanAndSearch .buttons {
  margin: 0;
  align-items: center;
}
.app-gbd .scroller #humanAndSearch .buttons button {
  padding: 4px 24px;
  height: 20px;
}
.app-gbd .addButtons button {
  line-height: 12px;
  padding: 4px 24px !important;
  font-size: 12px;
  height: 20px !important;
}
.app-gbd [title*="e"] {
  cursor: help;
}
.app-gbd [title*="e"] * {
  cursor: help;
}
.app-gbd * {
  max-width: 100vw;
}
.app-gbd page-advancedSearch,
.app-gbd page-coverage,
.app-gbd page-details,
.app-gbd page-explore,
.app-gbd page-notfound,
.app-gbd page-quicksearch,
.app-gbd page-reports,
.app-gbd page-saved-items,
.app-gbd page-similarlogo,
.app-gbd page-similarname,
.app-gbd page-goods-services,
.app-gbd page-export,
.app-gbd page-news,
.app-gbd chatbot {
  flex-grow: 1;
  width: calc(100vw);
  grid-column-start: 2;
  grid-row-start: 3;
  position: relative;
  display: grid;
  grid-template-rows: [nav-bar] auto [rest] 100fr;
}
.app-gbd page-advancedSearch .scroller,
.app-gbd page-coverage .scroller,
.app-gbd page-details .scroller,
.app-gbd page-explore .scroller,
.app-gbd page-notfound .scroller,
.app-gbd page-quicksearch .scroller,
.app-gbd page-reports .scroller,
.app-gbd page-saved-items .scroller,
.app-gbd page-similarlogo .scroller,
.app-gbd page-similarname .scroller,
.app-gbd page-goods-services .scroller,
.app-gbd page-export .scroller,
.app-gbd page-news .scroller,
.app-gbd chatbot .scroller,
.app-gbd page-advancedSearch graphs,
.app-gbd page-coverage graphs,
.app-gbd page-details graphs,
.app-gbd page-explore graphs,
.app-gbd page-notfound graphs,
.app-gbd page-quicksearch graphs,
.app-gbd page-reports graphs,
.app-gbd page-saved-items graphs,
.app-gbd page-similarlogo graphs,
.app-gbd page-similarname graphs,
.app-gbd page-goods-services graphs,
.app-gbd page-export graphs,
.app-gbd page-news graphs,
.app-gbd chatbot graphs {
  padding: 1px 40px 15px 40px;
  display: flex;
}
.app-gbd page-advancedSearch .scroller .b-page-header,
.app-gbd page-coverage .scroller .b-page-header,
.app-gbd page-details .scroller .b-page-header,
.app-gbd page-explore .scroller .b-page-header,
.app-gbd page-notfound .scroller .b-page-header,
.app-gbd page-quicksearch .scroller .b-page-header,
.app-gbd page-reports .scroller .b-page-header,
.app-gbd page-saved-items .scroller .b-page-header,
.app-gbd page-similarlogo .scroller .b-page-header,
.app-gbd page-similarname .scroller .b-page-header,
.app-gbd page-goods-services .scroller .b-page-header,
.app-gbd page-export .scroller .b-page-header,
.app-gbd page-news .scroller .b-page-header,
.app-gbd chatbot .scroller .b-page-header,
.app-gbd page-advancedSearch graphs .b-page-header,
.app-gbd page-coverage graphs .b-page-header,
.app-gbd page-details graphs .b-page-header,
.app-gbd page-explore graphs .b-page-header,
.app-gbd page-notfound graphs .b-page-header,
.app-gbd page-quicksearch graphs .b-page-header,
.app-gbd page-reports graphs .b-page-header,
.app-gbd page-saved-items graphs .b-page-header,
.app-gbd page-similarlogo graphs .b-page-header,
.app-gbd page-similarname graphs .b-page-header,
.app-gbd page-goods-services graphs .b-page-header,
.app-gbd page-export graphs .b-page-header,
.app-gbd page-news graphs .b-page-header,
.app-gbd chatbot graphs .b-page-header,
.app-gbd page-advancedSearch .scroller .b-step,
.app-gbd page-coverage .scroller .b-step,
.app-gbd page-details .scroller .b-step,
.app-gbd page-explore .scroller .b-step,
.app-gbd page-notfound .scroller .b-step,
.app-gbd page-quicksearch .scroller .b-step,
.app-gbd page-reports .scroller .b-step,
.app-gbd page-saved-items .scroller .b-step,
.app-gbd page-similarlogo .scroller .b-step,
.app-gbd page-similarname .scroller .b-step,
.app-gbd page-goods-services .scroller .b-step,
.app-gbd page-export .scroller .b-step,
.app-gbd page-news .scroller .b-step,
.app-gbd chatbot .scroller .b-step,
.app-gbd page-advancedSearch graphs .b-step,
.app-gbd page-coverage graphs .b-step,
.app-gbd page-details graphs .b-step,
.app-gbd page-explore graphs .b-step,
.app-gbd page-notfound graphs .b-step,
.app-gbd page-quicksearch graphs .b-step,
.app-gbd page-reports graphs .b-step,
.app-gbd page-saved-items graphs .b-step,
.app-gbd page-similarlogo graphs .b-step,
.app-gbd page-similarname graphs .b-step,
.app-gbd page-goods-services graphs .b-step,
.app-gbd page-export graphs .b-step,
.app-gbd page-news graphs .b-step,
.app-gbd chatbot graphs .b-step,
.app-gbd page-advancedSearch .scroller .b-edit-panel,
.app-gbd page-coverage .scroller .b-edit-panel,
.app-gbd page-details .scroller .b-edit-panel,
.app-gbd page-explore .scroller .b-edit-panel,
.app-gbd page-notfound .scroller .b-edit-panel,
.app-gbd page-quicksearch .scroller .b-edit-panel,
.app-gbd page-reports .scroller .b-edit-panel,
.app-gbd page-saved-items .scroller .b-edit-panel,
.app-gbd page-similarlogo .scroller .b-edit-panel,
.app-gbd page-similarname .scroller .b-edit-panel,
.app-gbd page-goods-services .scroller .b-edit-panel,
.app-gbd page-export .scroller .b-edit-panel,
.app-gbd page-news .scroller .b-edit-panel,
.app-gbd chatbot .scroller .b-edit-panel,
.app-gbd page-advancedSearch graphs .b-edit-panel,
.app-gbd page-coverage graphs .b-edit-panel,
.app-gbd page-details graphs .b-edit-panel,
.app-gbd page-explore graphs .b-edit-panel,
.app-gbd page-notfound graphs .b-edit-panel,
.app-gbd page-quicksearch graphs .b-edit-panel,
.app-gbd page-reports graphs .b-edit-panel,
.app-gbd page-saved-items graphs .b-edit-panel,
.app-gbd page-similarlogo graphs .b-edit-panel,
.app-gbd page-similarname graphs .b-edit-panel,
.app-gbd page-goods-services graphs .b-edit-panel,
.app-gbd page-export graphs .b-edit-panel,
.app-gbd page-news graphs .b-edit-panel,
.app-gbd chatbot graphs .b-edit-panel {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: 100vw;
}
@media screen and (max-width: 800px) {
  .app-gbd page-advancedSearch .scroller,
  .app-gbd page-coverage .scroller,
  .app-gbd page-details .scroller,
  .app-gbd page-explore .scroller,
  .app-gbd page-notfound .scroller,
  .app-gbd page-quicksearch .scroller,
  .app-gbd page-reports .scroller,
  .app-gbd page-saved-items .scroller,
  .app-gbd page-similarlogo .scroller,
  .app-gbd page-similarname .scroller,
  .app-gbd page-goods-services .scroller,
  .app-gbd page-export .scroller,
  .app-gbd page-news .scroller,
  .app-gbd chatbot .scroller,
  .app-gbd page-advancedSearch graphs,
  .app-gbd page-coverage graphs,
  .app-gbd page-details graphs,
  .app-gbd page-explore graphs,
  .app-gbd page-notfound graphs,
  .app-gbd page-quicksearch graphs,
  .app-gbd page-reports graphs,
  .app-gbd page-saved-items graphs,
  .app-gbd page-similarlogo graphs,
  .app-gbd page-similarname graphs,
  .app-gbd page-goods-services graphs,
  .app-gbd page-export graphs,
  .app-gbd page-news graphs,
  .app-gbd chatbot graphs {
    padding: 0 10px 15px 10px;
  }
}
.app-gbd > page-help {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
}
.app-gbd > page-results,
.app-gbd > page-visu {
  display: grid;
  grid-template-rows: [nav] auto [hoc] 0 [rest] 100fr;
  width: calc(100vw);
  overflow: hidden;
  flex-grow: 1;
}
.app-gbd > page-results > results,
.app-gbd > page-visu > results,
.app-gbd > page-results > graphs,
.app-gbd > page-visu > graphs {
  overflow: hidden;
}
.app-gbd > page-results > results .scroller,
.app-gbd > page-visu > results .scroller,
.app-gbd > page-results > graphs .scroller,
.app-gbd > page-visu > graphs .scroller {
  padding: 0 40px;
}
@media screen and (max-width: 800px) {
  .app-gbd > page-results > results .scroller,
  .app-gbd > page-visu > results .scroller,
  .app-gbd > page-results > graphs .scroller,
  .app-gbd > page-visu > graphs .scroller {
    padding: 0 10px;
  }
}
.app-gbd > page-export {
  display: block;
}
.app-gbd suggest-multiselect {
  display: flex;
  position: relative;
  background: #fff;
  outline: #e3e3e3 solid 1px;
  height: 60px;
}
.heightWithoutHeader {
  height: calc( 100vh - 48px );
}
[data-tippy-root] .tippy-box {
  background: #ededed;
  border: #cbcbcb solid 2px;
  color: #1a1a1a;
}
[data-tippy-root] .tippy-box .logoBig {
  margin-bottom: 1ch;
  max-width: 340px;
  max-height: 340px;
  text-align: center;
}
[data-tippy-root] .tippy-box .logoBig img {
  max-width: 330px;
  max-height: 330px;
}
[data-tippy-root] .tippy-box .brandName {
  font-size: initial;
  font-weight: bold;
}
.tippy-box:has(div:empty) {
  display: none;
}
